<template>
 <div>
  <svg height="60" viewBox="0 0 60 60" width="60" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
   <defs>
    <linearGradient gradientUnits="objectBoundingBox" id="linear-gradient" x1="0.864" x2="0.167" y2="1.067">
     <stop :stop-color="color1" offset="0"/>
     <stop :stop-color="color2" offset="1"/>
    </linearGradient>
   </defs>
   <g id="Email" transform="translate(-130 -528)">
    <rect :fill="background" data-name="Rectangle 73" height="60" id="Rectangle_73" transform="translate(130 528)" width="60"/>
    <path d="M26.4,6H5.6A2.6,2.6,0,0,0,3.013,8.6L3,24.2a2.608,2.608,0,0,0,2.6,2.6H26.4A2.608,2.608,0,0,0,29,24.2V8.6A2.608,2.608,0,0,0,26.4,6Zm0,5.2L16,17.7,5.6,11.2V8.6L16,15.1,26.4,8.6Z" data-name="Icon material-email" id="Icon_material-email" style="fill: url(#linear-gradient);" transform="translate(144 542)"/>
   </g>
  </svg>
 </div>
</template>
<script>
export default {
  props: ["color1", "color2", "background"],
};
</script>